import React from 'react';
import './App.css';
import painImage from './pain.webp';  // Изображение для десктопа
import painImageMobile from './pain-m.webp';  // Изображение для мобильных устройств
import productImage from './product.webp';  // Импорт изображения продукта
import badBreathIcon from './bad_breath.png';
import gumsIcon from './gums.png';
import crookedIcon from './crooked.png';
import painIcon from './pain.png';







function TransitPage() {
  return (
<div style={styles.pageContainer}>
  {/* Header Section */}
  <header style={styles.header}>
  <h1 style={{ ...styles.mainTitle, fontSize: '2.5em', fontWeight: 'bold', lineHeight: '1.2' }}>
    Say Goodbye to Bleeding Gums and Bad Breath!
  </h1>
  <p style={{ 
  ...styles.subTitle, 
  fontSize: '1.4em', 
  marginTop: '15px', 
  textAlign: 'center', 
  color: '#FFFFFF', // белый цвет
  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.4)', // добавим тень для выделения
}}>
  Discover how ProDentim can restore your oral health 
  <span style={{ fontWeight: 'bold', color: '#FFD700' }}> without costly dental visits!</span>
</p>


    {/* Adaptive image */}
    <div style={styles.imageWrapper}>
    <picture>
    <source media="(max-width: 600px)" srcSet={painImageMobile} />
    <img src={painImage} alt="Painful Teeth" className="adaptiveImage" style={{ width: '100%', borderRadius: '10px' }} />
  </picture>
    </div>
 
 
  </header>
   
  <div className="ctaWrapper">
  <p className="ctaText">Transform your oral health today – act now!</p>
  <button className="ctaButton" onClick={() => window.location.href = 'https://prodentim24.com/text.php#aff=alexontherock'}>
    GET STARTED NOW
  </button>
</div>


<div className="problemSection">
  <h2 className="problemTitle">Have you faced these problems?</h2>
  <ul className="problemList">
    <li className="problemListItem">
      <img src={gumsIcon} alt="Bleeding gums" className="problemIcon" />
      <span className="problemText"><strong>Bleeding gums</strong> after brushing your teeth.</span>
    </li>
    <li className="problemListItem">
      <img src={badBreathIcon} alt="Bad breath" className="problemIcon" />
      <span className="problemText"><strong>Bad breath</strong> that persists even after thorough care.</span>
    </li>
    <li className="problemListItem">
      <img src={painIcon} alt="Pain and discomfort" className="problemIcon" />
      <span className="problemText"><strong>Pain and discomfort</strong> while chewing, weakened teeth.</span>
    </li>
    <li className="problemListItem">
      <img src={crookedIcon} alt="Fear of losing teeth" className="problemIcon" />
      <span className="problemText"><strong>Fear of losing teeth</strong> due to gum disease and inability to afford expensive dental procedures.</span>
    </li>
  </ul>





    <p className="problemDescription">
      Many people face these issues without realizing that they can be solved more easily and affordably. The root of many gum and tooth problems lies in an imbalance of bacteria in the mouth, which leads to inflammation and tooth decay.
    </p>
  </div>

  {/* Solution Section */}
  <section className="solutionSection">
    <h2 className="sectionTitle">ProDentim — the natural solution for restoring gum and tooth health</h2>
    <p className="solutionText">
      ProDentim is a probiotic supplement that restores the balance of beneficial bacteria in the mouth, helping to improve gum and tooth health. It is based on scientific research confirming the effectiveness of probiotics in dentistry.
    </p>

{/* Product Image Section */}
<section className="productSection">
  <h2 className="sectionTitle">ProDentim — your solution for oral health</h2>
  <div className="imageWrapper">
    <img src={productImage} alt="ProDentim product" className="adaptiveImage" />
  </div>
</section>
{/* Текст со стрелками перед видео */}
<div className="attentionText" style={{  textAlign: 'center', margin: '20px 0', fontWeight: 'bold', fontSize: '24px', color: '#003366'  }}>
  ⬇ Find out how ProDentim will transform your smile! ⬇
</div>
      {/* Video Player */}
  <div style={styles.videoWrapper}>
    <iframe
      src="https://www.youtube.com/embed/M-cdVoY7jn0"
      title="ProDentim Introduction Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={styles.video}
    ></iframe>
  </div>



 {/* Text and Video Frame */}
 <div style={styles.videoDescriptionWrapper}>
    <h3>Dr. Drew Sutton explains</h3>
    <p>
    In this video, <strong>Dr. Drew Sutton, an experienced specialist in dentistry</strong>, shares a revolutionary solution for oral health — <strong>Prodentim</strong>. If you're tired of ineffective toothpaste and endless trips to the dentist, Dr. Sutton explains why Prodentim could be your <strong>best ally in fighting cavities, gum inflammation, and bad breath</strong>. Learn how this natural probiotic <strong>restores the balance of good bacteria and enhances your oral health</strong>. Don’t miss the chance to hear from a true professional about how to achieve a radiant smile!
    </p>
    
  </div>



  <div style={{ background: 'linear-gradient(90deg, #0066cc, #003399)', padding: '20px', borderRadius: '8px', margin: '20px 0', textAlign: 'center' }}>
    <p style={{ color: '#fff', fontSize: '20px', margin: '0', fontWeight: 'bold' }}>
        Go to the <a href="https://prodentim24.com/text.php#aff=alexontherock" style={{ color: '#ffd700', textDecoration: 'underline', fontWeight: 'bold' }}>official website</a> and start the journey to healthy gums now! Plus, get 2 free bonuses with your order!
    </p>
    <button className="ctaButton" style={{ marginTop: '10px' }} onClick={() => window.location.href = 'https://prodentim24.com/text.php#aff=alexontherock'}>
        GET STARTED NOW
    </button>
</div>









<h2 className="sectionTitle">Why does ProDentim really work?</h2>

<ul className="solutionList">
  <li className="solutionItem">
    <b>Restoration of oral flora:</b> Probiotics such as <i>Lactobacillus Paracasei</i> and <i>Lactobacillus reuteri</i> help reduce gum inflammation and bleeding in patients with gingivitis.{' '}
    <button className="linkButton" onClick={() => window.open('https://www.mdpi.com/2079-6382/10/6/713', '_blank')}>MDPI (research link)</button>
    <button className="linkButton" onClick={() => window.open('https://www.cureus.com/articles/25752-the-effect-of-lactobacillus-probiotics-on-periodontal-disease', '_blank')}>Cureus (research link)</button>
    <p className="researchSummary">Studies showed a significant reduction in gum bleeding and inflammation, demonstrating the effectiveness of these probiotics in oral care.</p>
  </li>

  <li className="solutionItem">
    <b>Fresh breath and microbiome balance:</b> Probiotic <i>Lactobacillus salivarius</i> has been found to reduce the amount of volatile sulfur compounds responsible for bad breath, normalizing the microbiome.{' '}
    <button className="linkButton" onClick={() => window.open('https://www.frontiersin.org/articles/10.3389/fcimb.2020.606889/full', '_blank')}>Frontiers (research link)</button>
    <p className="researchSummary">Research indicates that <i>Lactobacillus salivarius</i> effectively reduced sulfur compounds in the mouth, helping to eliminate bad breath and balance the oral flora.</p>
  </li>

  <li className="solutionItem">
    <b>Cavity protection:</b> Probiotic strains such as <i>Lactobacillus reuteri</i> reduce levels of bacteria that cause cavities, such as <i>Streptococcus mutans</i>, preventing tooth enamel destruction.{' '}
    <button className="linkButton" onClick={() => window.open('https://www.mdpi.com/2079-6382/10/6/713', '_blank')}>MDPI (research link)</button>
    <p className="researchSummary">The studies showed that <i>Lactobacillus reuteri</i> significantly reduced the number of cavity-causing bacteria, thus aiding in the prevention of enamel destruction.</p>
  </li>
</ul>
</section>


<section style={styles.ctaSection}>
  <h3 style={styles.ctaTitle}>You can read customer reviews and find more useful information on the <a href="https://prodentim24.com/text.php#aff=alexontherock" className="customLink">official website</a></h3>
  <button className="ctaButton" onClick={() => window.location.href = 'https://prodentim24.com/text.php#aff=alexontherock'}>
  SEE DETAILS
  </button>
</section>



</div>

  );
}


// Получаем элементы всплывающего окна
const exitPopupOverlay = document.getElementById('exitPopupOverlay');
const exitPopup = document.getElementById('exitPopup');
const exitPopupYes = document.getElementById('exitPopupYes');
const exitPopupNo = document.getElementById('exitPopupNo');

let popupShownCount = 0; // Счетчик появления окна
const maxPopupShows = 2; // Максимальное количество раз, когда окно может появиться
let popupAlreadyShown = false; // Флаг для отслеживания, было ли показано окно при текущем уходе

// Функция для показа всплывающего окна
function showExitPopup() {
    if (popupShownCount >= maxPopupShows || popupAlreadyShown) return; // Проверяем, не превышено ли максимальное количество

    exitPopupOverlay.style.display = 'block';
    exitPopup.style.display = 'block';
    setTimeout(() => {
        exitPopupOverlay.style.opacity = '1';
        exitPopup.style.opacity = '1';
    }, 10); // Небольшая задержка для плавного появления
    
    popupShownCount++; // Увеличиваем счетчик показов
    popupAlreadyShown = true; // Устанавливаем флаг, что окно было показано
}

// Функция для скрытия всплывающего окна
function hideExitPopup() {
    exitPopupOverlay.style.opacity = '0';
    exitPopup.style.opacity = '0';
    setTimeout(() => {
        exitPopupOverlay.style.display = 'none';
        exitPopup.style.display = 'none';
    }, 300); // Скрываем через 300 мс для плавности
}

// Обработчик для закрытия окна при клике на 'Не интересно'
exitPopupNo.addEventListener('click', hideExitPopup);

// Обработчик для перехода на сайт при клике на 'Интересно'
exitPopupYes.addEventListener('click', function() {
    window.location.href = 'https://prodentim24.com/text.php#aff=alexontherock';
});

// Отслеживание ухода мыши за пределы верхнего экрана (для десктопа)
document.addEventListener('mouseleave', function(event) {
    if (event.clientY <= 0 && !popupAlreadyShown) {
        showExitPopup();
    }
});

// Отслеживание ухода с экрана для мобильных (iOS и Android)
document.addEventListener('touchstart', function(event) {
    // Проверяем, если пользователь касается верхней части экрана, мы показываем попап
    if (event.touches[0].clientY < 50 && !popupAlreadyShown) {
        showExitPopup();
    }
});

// Отслеживание изменения видимости вкладки
document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'hidden' && !popupAlreadyShown) {
        showExitPopup();
    }
});
// Ваш текущий код остается неизменным

// Добавляем обработчик для отслеживания скрытия страницы
document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'hidden' && !popupAlreadyShown) {
        showExitPopup();
    }
});
















// CSS styles (inline for simplicity)
const styles = {
  pageContainer: {
    fontFamily: 'Arial, sans-serif',
    margin: '0 auto',
    padding: '20px',
    maxWidth: '900px',
    backgroundColor: '#f7f9fb',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    lineHeight: '1.6',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
    padding: '20px',
    background: 'linear-gradient(135deg, #0056b3, #007BFF)', // добавил градиент
    color: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // немного увеличил тень для выделения
  },


  subTitle: {
    fontSize: '20px',
    color: '#fff',
  },
  
  videoDescriptionWrapper: {
    backgroundColor: 'rgba(255, 140, 0, 0.1)', /* Легкий оранжевый фон */
    border: '1px solid rgba(255, 140, 0, 0.5)', /* Оранжевая рамка */
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '20px',
    color: '#333',
  },
  
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%', /* Соотношение сторон 16:9 */
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    backgroundColor: '#000',
    borderRadius: '8px',
    marginTop: '16px', /* Отступ сверху */
    marginBottom: '16px', /* Отступ снизу */
  },
  
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },

  
  sectionTitle: {
    fontSize: '28px',
    fontWeight: '600',
    color: '#333',
    marginBottom: '20px',
    borderBottom: '2px solid #007BFF',
    display: 'inline-block',
  },
  
  
  linkButton: {
    padding: '6px 12px',
    marginLeft: '10px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  ctaSection: {
    textAlign: 'center',
    padding: '20px',
    background: 'linear-gradient(90deg, #0056b3, #007BFF)', // Добавил градиент от темно-синего к более светлому
    borderRadius: '8px',
    color: '#fff',
    marginBottom: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' // Добавил легкую тень для выделения
},

  ctaTitle: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '20px',
  },


  
};


export default TransitPage;
